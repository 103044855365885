import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";

export interface Event {
    id: number;
    title: string;
    date: string;
    // Add more fields as necessary
  }
  

const CharityEvents: React.FC = () => {
  const { charityId } = useParams();
  const [events, setEvents] = useState<Event[]>([]);
  useDocumentTitle(`Events`);

  useEffect(() => {
    fetch(`https://api.charitales.baboons.tech/api/charities_detailed/${charityId}/agendas/`)
      .then((response) => response.json())
      .then((data) => setEvents(data))
      .catch((error) => console.error("Error:", error));
  }, [charityId]);

  return (
    <div>
      <h2>Events</h2>
      <ul>
        {events.map((event) => (
          <li key={event.id}>
            <Link to={`/charities_detailed/${charityId}/events/${event.id}`}>
              {event.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CharityEvents;

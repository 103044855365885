import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const VerifyPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      // Redirect to the app with the token
      window.location.href = `charitales://verify?token=${token}`;
    } else {
      // Handle the case where there's no token
      console.error("No token provided");
      // You might want to redirect to an error page or the home page
    }
  }, [location]);

  return (
    <div>
      <h1>Verifying...</h1>
      <p>
        If you're not redirected automatically, please open the Charitales app
        manually.
      </p>
    </div>
  );
};

export default VerifyPage;

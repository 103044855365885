import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";

export interface Post {
  id: number;
  title: string;
  content: string;
  charity_name: string;
  // Add more fields as necessary
}

const PostDetail: React.FC = () => {
  const { charityId, postId } = useParams();
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    fetch(`https://api.charitales.baboons.tech/api/charities_detailed/${charityId}/posts/${postId}/`)
      .then((response) => response.json())
      .then((data) => setPost(data))
      .catch((error) => console.error("Error:", error));
      
  }, [charityId, postId]);

  useEffect(() => {
    document.title = `Een event van ${post?.charity_name}`;
  }, [post]);

  if (!post) return <div>Loading...</div>;

  return (
    <div>
      <h2>{post.title}</h2>
      <p>{post.content}</p>
      {/* Display more post details as needed */}
    </div>
  );
};

export default PostDetail;

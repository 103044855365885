import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";

export interface LearningVideo {
    id: number;
    name: string;
    video_url: string;
    // Add more fields as necessary
  }
  

const CharityLearningVideos: React.FC = () => {
  const { charityId } = useParams();
  const [learningVideos, setLearningVideos] = useState<LearningVideo[]>([]);
  useDocumentTitle(`Learning Videos`);

  useEffect(() => {
    fetch(`https://api.charitales.baboons.tech/api/charities_detailed/${charityId}/learning-videos/`)
      .then((response) => response.json())
      .then((data) => setLearningVideos(data))
      .catch((error) => console.error("Error:", error));
  }, [charityId]);

  return (
    <div>
      <h2>Learning Videos</h2>
      <ul>
        {learningVideos.map((learningVideo) => (
          <li key={learningVideo.id}>
            <Link to={`/charities_detailed/${charityId}/learning-videos/${learningVideo.id}`}>
              {learningVideo.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CharityLearningVideos;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import play_store from "../assets/images/Play_Store.png";
import app_store from "../assets/images/App_Store.png";
import useDocumentTitle from "../hooks/useDocumentTitle";

export interface Event {
  id: number;
  title: string;
  date: string;
  location: string;
  entire_day: string;
  start_time: string;
  end_time: string;
  charity_name: string;
  become_a_volunteer_url?: string;
  become_a_volunteer_url_title?: string;
  website_url?: string;
  charity_lottery_url?: string;
  // Add more fields as necessary
}

const EventDetail: React.FC = () => {
  const { charityId, eventId } = useParams();
  const [event, setEvent] = useState<Event | null>(null);

  useEffect(() => {
    fetch(
      `https://api.charitales.baboons.tech/api/charities_detailed/${charityId}/agendas/${eventId}/`
    )
      .then((response) => response.json())
      .then((data) => setEvent(data))
      .catch((error) => console.error("Error:", error));
  }, [charityId, eventId]);

  useEffect(() => {
    document.title = `Een event van ${event?.charity_name}`;
  }, [event]);
  console.clear();
  console.log("Variable", event);
  if (!event) return <div>Loading...</div>;

  return (
    <div className="component-wrapper">
      <Container className="container">
        <div className="main-container">
          <h1 className="heading1">{event.charity_name} wil graag onderstaand bericht en relevante links met je delen.</h1>

          <div className="event-container">
            <h2 className="event-container-text1">{event.date}</h2>
            <p className="event-container-text2">{event.title}</p>
            <p className="event-container-text3">{event.location}</p>
            {/* {event.entire_day && (
              <p className="event-container-text4">Gehele dag</p>
            )} */}
            {event.start_time && event.end_time ? (
              <p className="event-container-text5">
                {event.start_time} - {event.end_time}
              </p>
            ) : (
              <p className="event-container-text5">Gehele dag</p>
            )}
          </div>

          {event.become_a_volunteer_url && (
            <div className="link-btn-container">
              <a href={event.become_a_volunteer_url}>
              <p>{event?.become_a_volunteer_url_title ? event?.become_a_volunteer_url_title : "Word ambassadeur"}</p>
              </a>
            </div>
          )}
          {event.website_url && (
            <div className="link-btn-container">
              <a href={event.website_url}>
                <p>Krijg meer informatie</p>
              </a>
            </div>
          )}
          {event.charity_lottery_url && (
            <div className="link-btn-container">
              <a href={event.charity_lottery_url}>
                <p>Speel mee voor het goede doel</p>
              </a>
            </div>
          )}

          <div className="button-container">
            <h6>Toegang tot nog veel meer verhalen?</h6>
            <h1 className="heading1">Download nu de gratis app!</h1>
            <div>
              <img style={{cursor:'pointer'}} src={app_store} onClick={() => {
              window.location.href = "https://apps.apple.com/nl/app/charitales/id6450423419";
            }}/>
              <img style={{cursor:'pointer'}} src={play_store} onClick={() => {
              window.location.href = "https://play.google.com/store/apps/details?id=com.charitales&pcampaignid=web_share";
            }}/>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EventDetail;

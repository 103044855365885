// src/components/CharityDetail.tsx
import Container from "react-bootstrap/Container";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import play_store from "../assets/images/Play_Store.png";
import app_store from "../assets/images/App_Store.png";

interface CharityDetailProps {
  // Define the types for your charity detail properties
  charity_name: string;
  video_url: string;
  video_thumbnail_url: string;
  charity_title: string;
  become_a_volunteer_url?: string;
  become_a_volunteer_url_title?: string;
  website_url?: string;
  charity_lottery_url?: string;
  // Add more properties as needed
}

const CharityDetail: React.FC = () => {
  const { charityId } = useParams();
  const [charity, setCharity] = useState<CharityDetailProps | null>(null);
 

  useEffect(() => {
    fetch(
      `https://api.charitales.baboons.tech/api/get_charity_data/?charity_id=${charityId}`
    )
      .then((response) => response.json())
      .then((data) => setCharity(data))
      .catch((error) => console.error("Error fetching charity detail:", error));
  }, [charityId]);

  
  useEffect(() => {
    document.title = `Een video van ${charity?.charity_name}`
  }, [charity]);

  const [isThumbClick, setIsThumbClick] = useState(false);
  const handleClick = () => {
    setIsThumbClick(!isThumbClick);
  };

  if (!charity) return <div>Loading...</div>;

  return (
    <div className="component-wrapper">
      <Container className="container">
        <div className="main-container">
          <h1 className="heading1">
            {charity?.charity_name} wil graag onderstaand bericht en relevante links met je delen.
          </h1>
          <div className="video-container" onClick={handleClick}>
            <video
              controls
              poster={charity.video_thumbnail_url}
              src={charity.video_url}
              width="100%"
              className=""
            ></video>
            <div
              className={"thumb-wrap " + (isThumbClick ? "isThumbClick" : "")}
            >
              <img className="thumb" src={charity?.video_thumbnail_url} />
              <svg
                className="play-icon"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2410_308)">
                  <path
                    d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
                    fill="white"
                  />
                  <path
                    d="M25.5211 17.66C23.5212 16.4835 21 17.9255 21 20.2458V39.7553C21 42.0755 23.5212 43.5175 25.5211 42.3411L42.1042 32.5863C44.076 31.4264 44.076 28.5747 42.1042 27.4147L25.5211 17.66Z"
                    fill="#FD5A1C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2410_308">
                    <rect width="60" height="60" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          {charity.become_a_volunteer_url && (
            <div className="link-btn-container">
              <a href={charity.become_a_volunteer_url}>
              <p>{charity?.become_a_volunteer_url_title ? charity?.become_a_volunteer_url_title : "Word ambassadeur"}</p>
              </a>
            </div>
          )}
          {charity.website_url && (
            <div className="link-btn-container">
              <a href={charity.website_url}>
                <p>Krijg meer informatie</p>
              </a>
            </div>
          )}
          {charity.charity_lottery_url && (
            <div className="link-btn-container">
              <a href={charity.charity_lottery_url}>
                <p>Speel mee voor het goede doel</p>
              </a>
            </div>
          )}

          <div className="button-container">
            <h6>Toegang tot nog veel meer verhalen?</h6>
            <h1 className="heading1">Download nu de gratis app!</h1>
            <div>
              <img style={{cursor:'pointer'}} src={app_store} onClick={() => {
              window.location.href = "https://apps.apple.com/nl/app/charitales/id6450423419";
            }}/>
              <img style={{cursor:'pointer'}} src={play_store} onClick={() => {
              window.location.href = "https://play.google.com/store/apps/details?id=com.charitales&pcampaignid=web_share";
            }}/>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CharityDetail;

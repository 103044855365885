import frame from "../assets/images/Frame.png";
import Container from "react-bootstrap/Container";
import "../assets/styles/overviewPage.css";
import { GetAllPosts } from "../api";
import { useEffect, useState } from "react";
import { AllPosts, Posts } from "../types";
import axios from "axios";
import { Link } from "react-router-dom";
import CharitiesList from "../components/CharitiesList";
import useDocumentTitle from "../hooks/useDocumentTitle";

const OverviewPage = () => {
  const [posts, setPosts] = useState([]);
  const [coverPost, setCoverPost] = useState<Posts>();
  useDocumentTitle(`Charitales Nieuws`);

  // const getPost = () => {
  //   GetAllPosts()
  //     .then((data) => {
  //       //setPosts(data.results);
  //       console.log(data);
  //     })
  //     .catch((err) => console.log("cannott get data", err));
  // };
 
  const fetchPosts = async () => {
    try {
      const response = await axios.get(
        "https://api.charitales.baboons.tech/api/posts/"
      );
      const responseData = response.data.results;
      setPosts(responseData);
      const lastIndex: number = responseData.length - 1;
      setCoverPost(responseData[lastIndex]);
    } catch (err) {
      console.log(err);
    }
  };

  const GetStringDate = (dateString: string | any): string => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    //getPost();
    fetchPosts();
  }, []);

  return (
    <div className="Background">
      <Container className="main-container-wrapper">
        <div className="row pt-1">
          <div className="col-md-12 col-lg-8 p-4">
            <h3 className="pb-2 heading3">Alle activiteiten overzicht</h3>
            <div className="p-4 sub-container">
              <div className=" disply-control-wish">
                <div>
                  <img style={{width: '36px', height: '36px'}} src={coverPost?.author_charity_logo} />
                </div>
                <div className="make-wish-container">
                  <h5>{coverPost?.author}</h5>
                  <p className="info-para ">
                    {GetStringDate(coverPost?.created_at.toString())}
                  </p>
                </div>
              </div>
              {coverPost?.type === "image" ? (
              <img src={coverPost?.post_image} className="img-size" />
            ) : (
              <img src={coverPost?.thumbnail_url} className="img-size" />
            )}
              <h3 className="pt-3">{coverPost?.title}</h3>
              {/* <p className="text-para">{coverPost?.content}</p> */}
              <p
                className="text-para"
                dangerouslySetInnerHTML={{
                  __html: coverPost?.content as string,
                }}
              ></p>
              <div className=" display-control-date">
                <p className="info-para ">{coverPost?.author}</p>
                <p className="info-para ">
                  {GetStringDate(coverPost?.created_at.toString())}
                </p>
              </div>
            </div>

            <h4 className="mt-4 mb-3 heading4">Nieuw binnen</h4>
            <div className="p-4 sub-container sub-childerns">
            {posts &&
                posts.map((post: Posts) => (
              
                  <div key={post.id} className="row">
                    <div className="col-md-6 col-lg-8 li-list">
                      <Link
                        to={`news/${post.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <li className="li-list">{post.title}</li>
                      </Link>
                    </div>
                    <div className="col-md-6 col-lg-4 text-end info-para">
                      {post.author}&nbsp;
                      {GetStringDate(post.created_at.toString())}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-md-12 col-lg-4 p-3">
            <h4 className="mt-4 mb-3 heading4">Alle activiteiten</h4>
            <div className="p-4 sub-container sub-childerns">
            {posts &&
                [...posts].reverse().map((post: Posts) => (
                  <div key={post.id} className="row">
                    <div className="col-lg-12 col-md-6 col-sm-12 li-list">
                      <Link
                        to={`news/${post.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <li className="li-list">{post.title}</li>
                      </Link>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12 text-end info-para">
                      {post.author}&nbsp;
                      {GetStringDate(post.created_at.toString())}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Container>
      <CharitiesList />
    </div>
  );
};

export default OverviewPage;

import "./App.css";
import AmbassadorDetail from "./components/AmbassadorDetail";
import CharitiesList from "./components/CharitiesList";
import CharityAmbassadors from "./components/CharityAmbassadors";
import CharityDetail from "./components/CharityDetail";
import CharityEvents from "./components/CharityEvents";
import CharityLearningVideos from "./components/CharityLearningVideos";
import CharityPosts from "./components/CharityPosts";
import EventDetail from "./components/EventDetail";
import LearningVideoDetail from "./components/LearningVideoDetail";
import NavBar from "./components/NavBar";
import PostDetail from "./components/PostsDetail";
import OverviewPage from "./pages/OverviewPage";
import SinglePage from "./pages/SinglePage";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import VerifyPage from "./pages/VerifyPage";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <NavBar />
      <BrowserRouter>
        <Routes>
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="/news/:slug" element={<SinglePage />} />
          <Route path="/" element={<OverviewPage />} />
          <Route path="/charities/:charityId" element={<CharityDetail />} />
          <Route path="/charities" element={<CharitiesList />} />
          <Route
            path="/charities_detailed/:charityId/posts"
            element={<CharityPosts />}
          />
          <Route
            path="/charities_detailed/:charityId/posts/:postId"
            element={<PostDetail />}
          />
          <Route
            path="/charities_detailed/:charityId/events"
            element={<CharityEvents />}
          />
          <Route
            path="/charities_detailed/:charityId/events/:eventId"
            element={<EventDetail />}
          />
          <Route
            path="/charities_detailed/:charityId/ambassadors"
            element={<CharityAmbassadors />}
          />
          <Route
            path="/charities_detailed/:charityId/ambassadors/:ambassadorId"
            element={<AmbassadorDetail />}
          />

          <Route
            path="/charities_detailed/:charityId/learning-videos"
            element={<CharityLearningVideos />}
          />
          <Route
            path="/charities_detailed/:charityId/learning-videos/:learningVideoId"
            element={<LearningVideoDetail />}
          />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/Union.png";
import play_store from "../assets/images/Play_Store.png";
import app_store from "../assets/images/App_Store.png";
import "../assets/styles/navbar.css";

const NavBar = () => {
  return (
    <Navbar className="bg-body-white">
      <Container className="d-flex justify-content-center justify-content-md-between">
        <Navbar.Brand href="">
          <img
            src={logo}
            width="155"
            height="48"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
            onClick={() => {
              window.location.href = "/";
            }}
          />
        </Navbar.Brand>
        <Navbar.Brand href="" className="d-none d-md-block">
          <img
            src={app_store}
            
            height="40"
            className="d-inline-block align-top badge-margin"
            alt="React Bootstrap logo"
            onClick={() => {
              window.location.href = "https://apps.apple.com/nl/app/charitales/id6450423419";
            }}
            style={{cursor:'pointer'}}
          />
          <img
            src={play_store}
           
            height="40"
            className="d-inline-block align-top badge-margin"
            alt="React Bootstrap logo"
            onClick={() => {
              window.location.href = "https://play.google.com/store/apps/details?id=com.charitales&pcampaignid=web_share";
            }}
            style={{cursor:'pointer'}}
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default NavBar;

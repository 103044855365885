import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Charity {
  id: number;
  charity_name: string;
}

const CharitiesList: React.FC = () => {
  const [charities, setCharities] = useState<Charity[]>([]);

  useEffect(() => {
    fetch('https://api.charitales.baboons.tech/api/accounts/?user_type=charity')
      .then(response => response.json())
      .then(data => setCharities(data))
      .catch(error => console.error('Error fetching charities:', error));
  }, []);

  return (
    <div>
      <h2>Charities</h2>
      <ul>
        {charities.map((charity: Charity) => (
          <li key={charity.id}>
            <Link to={`/charities/${charity.id}`}>{charity.charity_name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CharitiesList;

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";

export interface Ambassador {
    id: number;
    name: string;
    video_url: string;
    // Add more fields as necessary
  }
  
  

const CharityAmbassadors: React.FC = () => {
  const { charityId } = useParams();
  const [ambassadors, setAmbassadors] = useState<Ambassador[]>([]);
  useDocumentTitle(`Ambassadors`);

  useEffect(() => {
    fetch(`https://api.charitales.baboons.tech/api/charities_detailed/${charityId}/ambassadors/`)
      .then((response) => response.json())
      .then((data) => setAmbassadors(data))
      .catch((error) => console.error("Error:", error));
  }, [charityId]);

  return (
    <div>
      <h2>Ambassadors</h2>
      <ul>
        {ambassadors.map((ambassador) => (
          <li key={ambassador.id}>
            <Link to={`/charities_detailed/${charityId}/ambassadors/${ambassador.id}`}>
              {ambassador.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CharityAmbassadors;

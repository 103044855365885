import { useEffect } from 'react';

const BASE_TITLE = 'Charitales News'; 

const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = `${BASE_TITLE} - ${title}`;

    if(title == "Charitales Nieuws") {
      document.title = `${title}`;
    } else {
      document.title = `${BASE_TITLE} - ${title}`;
    }
  }, [title]);
};

export default useDocumentTitle;

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";

export interface Post {
  id: number;
  title: string;
  content: string;
  // Add more fields as necessary
}

const CharityPosts: React.FC = () => {
  const { charityId } = useParams();
  const [posts, setPosts] = useState<Post[]>([]);
  useDocumentTitle(`Posts`);

  useEffect(() => {
    fetch(`https://api.charitales.baboons.tech/api/charities_detailed/${charityId}/posts/`)
      .then((response) => response.json())
      .then((data) => setPosts(data))
      .catch((error) => console.error("Error:", error));
  }, [charityId]);

  return (
    <div>
      <h2>Posts</h2>
      <ul>
        {posts.map((post) => (
          <li key={post.id}>
            <Link to={`/charities_detailed/${charityId}/posts/${post.id}`}>
              {post.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CharityPosts;

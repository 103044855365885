import Container from "react-bootstrap/Container";
import "../assets/styles/singlePage.css";
import frame2 from "../assets/images/Frame_2.png";
import play_store from "../assets/images/Play_Store.png";
import app_store from "../assets/images/App_Store.png";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import { Posts } from "../types";
import useDocumentTitle from "../hooks/useDocumentTitle";
import MetaTags from '../components/MetaTags';

const SinglePage = () => {
  const { slug } = useParams();
  const [postdata, setPostdata] = useState<Posts>();

  const fetchPosts = async () => {
    try {
      const response = await axios.get(
        `https://api.charitales.baboons.tech/api/posts/${slug}`
      );

      setPostdata(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const GetStringDate = (dateString: string | any): string => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    fetchPosts();
  }, [slug]);

  const metaTitle = postdata ? `${postdata.title} | ChariTales` : 'ChariTales';
  const metaDescription = postdata?.content 
    ? postdata.content.substring(0, 160) 
    : 'Een verhaal gedeeld via ChariTales';
  const metaImage = postdata?.post_image || 'https://nieuws.charitales.com/default-post-image.jpg';
  const metaUrl = `https://nieuws.charitales.com/news/${slug}`;

  return (
    <div className="component-wrapper">
      <MetaTags
        title={metaTitle}
        description={metaDescription}
        image={metaImage}
        url={metaUrl}
      />
      <Container className="container">
        <div className="main-container">
          <h1 className="heading1">{postdata?.author} wil graag onderstaand bericht en relevante links met je delen.</h1>
          <div className="subb-container">
            <div className="display-controle-wish">
              <img src={postdata?.author_charity_logo} />
              <div className="wish-text">
                <h4>{postdata?.author}</h4>
                <p>{GetStringDate(postdata?.created_at.toString())}</p>
              </div>
            </div>
            {postdata?.type === "image" ? (
              <img src={postdata?.post_image} className="img-border" />
            ) : (
              <video
                controls
                poster={postdata?.thumbnail_url}
                src={postdata?.video_url}
                width="100%"
                className="img-border"
              >
              </video>
            )}

            <h2 className="heading2">{postdata?.title}</h2>
            <p
              className="para-content"
              dangerouslySetInnerHTML={{
                __html: postdata?.content as string,
              }}
            ></p>

            <div className="display-control-date">
              <p>{postdata?.author}</p>
              <p>{GetStringDate(postdata?.created_at.toString())}</p>
            </div>
          </div>

          {postdata?.author_become_a_volunteer_url && (
            <div className="link-btn-container">
              <a href={postdata?.author_become_a_volunteer_url} target="_blank">
                <p>{postdata?.author_become_a_volunteer_url_title ? postdata?.author_become_a_volunteer_url_title : "Word ambassadeur"}</p>
              </a>
            </div>
          )}

          {postdata?.author_website_url && (
            <div className="link-btn-container">
              <a href={postdata?.author_website_url} target="_blank">
                <p>Krijg meer informatie</p>
              </a>
            </div>
          )}

          {postdata?.author_charity_lottery_url && (
            <div className="link-btn-container">
              <a href={postdata.author_charity_lottery_url} target="_blank">
                <p>Speel mee voor het goede doel</p>
              </a>
            </div>
          )}

          <div className="button-container">
            <h6>Wil je meer lezen en delen van deze organisatie?</h6>
            <h1 className="heading1">Download nu de gratis app, kies je goede doel en start!</h1>
            <div>
              <img style={{cursor:'pointer'}} src={app_store} onClick={() => {
              window.location.href = "https://apps.apple.com/nl/app/charitales/id6450423419";
            }}/>
              <img style={{cursor:'pointer'}} src={play_store} onClick={() => {
              window.location.href = "https://play.google.com/store/apps/details?id=com.charitales&pcampaignid=web_share";
            }}/>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SinglePage;
